import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'shards-react';

function IdeaAttachmentModal({ ideaItem, isVisible, type, onClick }) {
  const clientName = process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo';
  const urlFile = ideaItem.url;
  const mimeType = ideaItem.mime;
  const [loading, setLoading] = useState(true);

  const toggleModal = () => {
    if (onClick) onClick(!isVisible);
  };

  function hasPreview() {
    const previewableMimeTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'video/mp4',
      'video/webm',
      'video/ogg',
    ];

    return previewableMimeTypes.includes(mimeType);
  }

  function renderPreview() {
    if (!hasPreview()) {
      return <label>No preview available for this file type. Please download the attachment to review.</label>;
    }

    switch (mimeType) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return (
          <>
            {loading && (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
              </div>
            )}
            <img
              style={{ display: loading ? 'none' : 'block', height: 400, width: '100%', objectFit: 'contain' }}
              src={urlFile}
              alt="File Preview"
              onLoad={() => setLoading(false)}
            />
          </>
        );
      case 'application/pdf':
        return (
          <iframe
            style={{ height: 500, border: 0, width: '100%' }}
            src={urlFile}
            title="PDF Preview"
          ></iframe>
        );
      case 'video/mp4':
        return (
          <video style={{ height: 400, width: '100%' }} controls src={urlFile}>
            Your browser does not support the video tag.
          </video>
        );
      default:
        return <label>File type not supported for preview.</label>;
    }
  }

  const downloadFile = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Modal open={isVisible} toggle={toggleModal}>
      <ModalHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Attachment</div>
          <span
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '24px',
            }}
            onClick={toggleModal}
          >
            &times;
          </span>
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: hasPreview() ? '0px' : 'auto' }}>{renderPreview()}</ModalBody>
      <ModalFooter>
        <Button onClick={() => downloadFile(urlFile)}>Download</Button>
      </ModalFooter>
    </Modal>
  );
}

export default IdeaAttachmentModal;

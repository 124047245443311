import React from "react";

const ImplementDuplicate = ({ showA3, openImplementedForm, openDuplicateForm, openNotImplementedForm, progress }) => {

    const handleNotImplemented = () => {
        openNotImplementedForm()
    }

    const handleDuplicate = () => {
        openDuplicateForm()
    }

    const handleImplemented = () => {
        openImplementedForm()
        showA3(true)
    }

    return (
        <div style={{
            position: "fixed",
            bottom: "90px",
            width: "650px",
            backgroundColor: "white",
            textAlign: "center",
            padding: "16px 0",
            left: "calc(50% - 185px)",
            zIndex: "999"
        }}>
            <h5 style={{ color: "#000", fontWeight: "500" }}>What was the outcome of this opportunity?</h5>
            <p style={{ padding: "0 30px" }}>Please choose an option below and submit the required information to close this opportunity.</p>
            <hr />
            <div style={{
                display: "flex",
                justifyContent: "center",
                gap: "30px",
                padding: "20px"
            }}>
                <button
                    onClick={() => window.location.href = '/implement'}
                    className="btn btn-primary btn-pill"

                    style={{
                        padding: "9px 16px",
                        border: "3px solid rgb(0, 123, 255)",
                        color: "rgb(0, 123, 255)",
                        backgroundColor: "#fff",
                        borderRadius: "30px"
                    }}>Review Later</button>
                <button
                    onClick={handleNotImplemented}
                    className="btn btn-primary btn-pill"

                    style={{
                        padding: "9px 16px",
                        border: "3px solid rgb(0, 123, 255)",
                        color: "rgb(0, 123, 255)",
                        backgroundColor: "#fff",
                        borderRadius: "30px"
                    }}>Not Implemented</button>
                <button
                    onClick={handleDuplicate}
                    className="btn btn-primary btn-pill"

                    style={{
                        padding: "9px 16px",
                        border: "3px solid rgb(0, 123, 255)",
                        color: "rgb(0, 123, 255)",
                        backgroundColor: "#fff",
                        borderRadius: "30px"
                    }}>Duplicate</button>
                <button
                    onClick={handleImplemented}
                    className="btn btn-primary btn-pill"
                    style={{
                        padding: "9px 16px",
                        border: "3px solid rgb(0, 123, 255)",
                        color: "#fff",
                        backgroundColor: "rgb(0, 123, 255)",
                        borderRadius: "30px"
                    }}>Implemented</button>
            </div>
        </div>
    )
}

export default ImplementDuplicate;
import axios from 'axios';
import { authUser } from './userService';

const API_URL = process.env.REACT_APP_EP_API;

export const getUpcomingTasks = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const response = await axios.get(`${API_URL}/workflow/implement/tasks`, {
            headers: {
                ...authHeader,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching upcoming tasks:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getTasksByOpportunityId = async (id) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const response = await axios.get(`${API_URL}/opportunity/${id}/tasks`, {
            headers: {
                ...authHeader,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching upcoming tasks:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getInbox = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const response = await axios.get(`${API_URL}/workflow/implement`, {
            headers: {
                ...authHeader,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching inbox:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getOpportunityById = async (opportunityId) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const response = await axios.get(`${API_URL}/opportunity/${opportunityId}?viewFrom=implement`, {
            headers: {
                ...authHeader,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching opportunity by ID:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const deleteTaskById = async (taskId) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('id', taskId);

        const response = await axios.delete(`${API_URL}/workflow/implement/tasks`, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        });

        return response.data;
    } catch (error) {
        console.error('Error deleting task:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const reassignCoach = async (opportunityId, coaches) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('action', 'reassignCoach');
        coaches.forEach(coach => {
            formData.append('coachIds', coach.id);
        });
        
        const response = await axios.post(`${API_URL}/workflow/implement/${opportunityId}`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error reassigning coach:', error.response ? error.response.data : error.message);
        throw error;
    }
};


export const updateStatus = async (opportunityId, changes, progress) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('action', 'updateStatus');
        formData.append('changes', changes);
        formData.append('progress', progress);

        const response = await axios.post(`${API_URL}/workflow/implement/${opportunityId}`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error updating status:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const reassignImplementationLeader = async (opportunityId, leaderId) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('action', 'reassignImplementationLeader');
        formData.append('implementationLeaderId', leaderId);

        const response = await axios.post(`${API_URL}/workflow/implement/${opportunityId}`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error reassigning implementation leader:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const sendA3 = async (opportunityId, a3Data) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('action', 'submitA3');
        formData.append('originalSituation', a3Data.originalSituation);
        formData.append('goal', a3Data.goal);
        formData.append('goalAchieved', a3Data.goalAchieved);
        formData.append('canBeReplicated', a3Data.canBeReplicated);
        formData.append('fileId', a3Data.fileId);
        formData.append('additionalDetails', a3Data.additionalDetails);
        formData.append('achievedResults', a3Data.achievedResults);

        const response = await axios.post(`${API_URL}/workflow/implement/${opportunityId}`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error sending A3:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const sendDuplicate = async (opportunityId, data, action) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
    
    try {
        const formData = new FormData();
        formData.append('action', action);
        formData.append('fileId', data.fileId);
        formData.append('comments', data.additionalDetails);
        
        const response = await axios.post(`${API_URL}/workflow/implement/${opportunityId}`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error sending A3:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const createTask = async (opportunityId, deadline, description) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('opportunityId', opportunityId);
        formData.append('description', description);
        if(deadline)
            formData.append('deadline', deadline);

        const response = await axios.post(`${API_URL}/workflow/implement/tasks`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error creating task:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const addTask = async (taskId, description, deadline) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('id', taskId);
        formData.append('description', description);

        if (deadline) {
            formData.append('deadline', deadline);
        }

        const response = await axios.post(`${API_URL}/workflow/implement/tasks`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error adding task:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const updateTaskById = async (taskId, taskData) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
    try {
        const formData = new FormData();
        formData.append('id', taskId);
        formData.append('description', taskData.description);
        formData.append('isCompleted', taskData.completed);

        if(taskData.deadline)
            formData.append('deadline', taskData.deadline);
       

        const response = await axios.patch(`${API_URL}/workflow/implement/tasks`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error updating task:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getOpportunitiesWithFilters = async (filters) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    try {
      const params = new URLSearchParams();
  
      if (filters.departmentsIds) {
        filters.departmentsIds.forEach(id => params.append('departmentsIds', id));
      }
      if (filters.typesIds) {
        filters.typesIds.forEach(id => params.append('typesIds', id));
      }

      if (filters.isTransferred !== undefined) {
        params.append('isTransferred', filters.isTransferred);
      }
      if (filters.categoriesIds) {
        filters.categoriesIds.forEach(id => params.append('categoriesIds', id));
      }
  
      const response = await axios.get(`${API_URL}/workflow/evaluate?context=inbox&${params.toString()}`, {
        headers: authHeader,
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching opportunities with filters:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  

  
  export const changeTargetCompletionDate = async (opportunityId, newDeadline) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const formData = new FormData();
        formData.append('action', 'changeTargetCompletionDate');
        formData.append('completionDate', newDeadline);

        const response = await axios.post(`${API_URL}/workflow/implement/${opportunityId}`, formData, {
            headers: {
                ...authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error changing target completion date:', error.response ? error.response.data : error.message);
        throw error;
    }
};
import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";

import { withTranslation } from "react-i18next";
import PageTitle from "../components/common/PageTitle";
import ResponsiveTable from "../components/responsive-table/ResponsiveTable";
import { fetchRecognizeHistory } from "../services/recognizeService";
import CompletedIdeaFormNew from "./CompletedIdeaFormNew";

class RecognitionHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      tableData: [],
      filteredIdeas: [],
      open: false, // To control modal state
      ideaItem: null, // Track the selected idea
      showPreviousIcon: false,
      search: ""
    };

    this.searcher = null;
    this.getStatusClass = this.getStatusClass.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.showIdea = this.showIdea.bind(this);
    this.dismissModal = this.dismissModal.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      showView: false,
    });
    this.fetchIdeas();
  }

  async fetchIdeas() {
    const results = await fetchRecognizeHistory();
    this.setState({ tableData: results, filteredIdeas: results });
  }

  getStatusClass(status) {
    const statusMap = {
      Cancelled: "danger",
      Complete: "success",
      Pending: "warning",
    };

    return `text-${statusMap[status]}`;
  }

  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value,
    });
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      const filteredData = this.state.tableData.filter(idea =>
        idea.title.toLowerCase().includes(searchTerm) ||
        idea.category.toLowerCase().includes(searchTerm) ||
        idea.proponent.toLowerCase().includes(searchTerm) ||
        idea.status.toLowerCase().includes(searchTerm)
      );
      this.setState({ filteredIdeas: filteredData });
    });
  }

  showIdea(index) {
    const ideaItem = this.state.tableData[index]; // Get selected idea by index

    // Fetch full idea details if necessary, or use already available data
    this.setState({
      open: true,
      ideaItem: ideaItem, // Set selected idea for viewing
    });
  }

  dismissModal() {
    this.setState({
      open: false,
    });
  }

  handlePreviousClick = () => {
    this.setState({
      open: false,
    });
  };

  getNormalView() {
    const { filteredIdeas, pageSize, pageSizeOptions } = this.state;
    const { t } = this.props;

    const tableColumns = [
      {
        Header: t("IDEA_UPDATE_NUM"),
        accessor: "num",
        maxWidth: 150,
        className: "text-center",
      },
      {
        Header: t("TRANSACTION_EMPLOYEE_NAME"),
        accessor: "proponent",
        className: "text-center",
        style: { "white-space": "unset" },
        minWidth: 150,
      },
      {
        Header: t("CATEGORY"),
        accessor: "category",
        maxWidth: 150,
        style: { color: '#17c671' },
        className: "text-center"
      },
      {
        Header: t("STATUS"),
        accessor: "status",
        maxWidth: 150,
        className: "text-center",
        Cell: row => (
          <div className="d-table mx-auto">
            <Badge pill theme={getBadgeType(row.value)}>
              {row.value === 'SOMETIDA' ? 'Submitted' : row.value}
            </Badge>
          </div>
        )
      },
      {
        Header: 'Actions',
        accessor: "actions",
        maxWidth: 140,
        minWidth: 140,
        sortable: false,
        Cell: (row) => (
          <>
            <Badge theme={getBadgeType(row.original.status)} onClick={() => this.showIdea(row.index)} pill style={{ cursor: 'pointer', marginRight: '10px' }} outline>
              View
            </Badge>
          </>
          // <ButtonGroup size="sm" className="d-table mx-auto">
          //   <Button theme="white" >
          //     <i className="material-icons">visibility</i>
          //   </Button>
          // </ButtonGroup>
        ),
      },
    ];

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={'History'}
            subtitle={"Recognition Workflow"}
            className="text-sm-left mb-3"
          />
        </Row>
        <Card className={'transparent-responsive-card lg:p-0'}>
        <CardHeader className="p-0 responsive-table-filter">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>{t("SHOW")}</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size + " " + t("ROWS")}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ResponsiveTable
                columns={tableColumns}
                data={filteredIdeas}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }

  render() {
    const { open, ideaItem } = this.state;

    return open ? (
      <CompletedIdeaFormNew
        currIdea={ideaItem}
        closeA3={() => this.dismissModal()}
        stage="recognized"
        customTitle="Recognition"
        view="recognition"
      />
    ) : (
      this.getNormalView()
    );
  }
}

function getBadgeType(itemStatus) {
  const statusMap = {
    "Complete": "success",
    "Saved": "warning",
    "Canceled": "danger"
  };
  return statusMap[itemStatus];
}

export default withTranslation()(RecognitionHistory);

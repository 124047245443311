import React from "react";
import Select from "react-select";
import { Button, Col, DatePicker, FormInput, Row } from "shards-react";
import Swal from 'sweetalert2';
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { deleteRecognizeById, submitRecognitionReward, updateRecognitionRewardById } from "../../services/recognizeService";
import CustomFileWithProgressUpload from "../components-overview/CustomFileWithProgressUpload";
import ExecutionSelectNew from "./ExecutionSelectNew";
import IdeaAttachmentModal from "./IdeaAttachmentModal";
import ResponseRequired from "./ResponseRequired";


class RecognitionItem extends React.Component {
  constructor(props) {
    super(props);
    const recognition = this.props.recognition || {};
    const isPrefilled = !!recognition.id;
    const selectedLevel = recognition.level

    const executionRes = isPrefilled && recognition.ownerId
      ? { value: recognition.ownerId.id, label: recognition.ownerId.label }
      : recognition.ownerId
        ? { value: recognition.ownerId.id, label: recognition.ownerId.label }
        : null;

    this.state = {
      recognitionDate: recognition.date || new Date(),
      recognitionStatus: recognition.active || false,
      economicReward: isPrefilled ? recognition.economicReward : recognition.economicReward || false,
      selectedType: isPrefilled ? recognition.type : recognition.type || null,
      load: 0,
      selectedEmployees: isPrefilled
        ? recognition.employeeIds.map((employee) => ({
          value: employee.id,
          label: employee.label,
        }))
        : (recognition.employeeIds || []).map((employee) => ({
          value: employee.id,
          label: employee.label,
        })),

      // Now selectedLevel is an object as required by the Select component
      selectedLevel: selectedLevel,

      // Activity owner is also set as an object with value and label
      executionRes: executionRes,

      owner: isPrefilled ? recognition.ownerId.label : (recognition.ownerId ? recognition.ownerId.label : null),
      selectedDate: isPrefilled ? recognition.targetDate : recognition.targetDate || null,
      rewardAmount: recognition.amountReward || "",
      categoryIsOpen: true,
      attachment: recognition.attachment || null,
      isPrefilled: isPrefilled,
      comments: recognition.comments || "", // Added for comments
      file: '', // Added for file upload
      modalVisible: false,
      recognitionModalOpen: false,
      openCacheSubmit: this.props.openCacheSubmit
    };
  }

  toggleRecognitionStatus = () => {
    this.setState({ recognitionStatus: !this.state.recognitionStatus });
  };

  handleTypeChange = (selectedOption) => {
    this.setState({ selectedType: selectedOption.value });
  };

  handleEmployeeChange = (selectedEmployees) => {
    this.setState({ selectedEmployees });
  };

  handleLevelChange = (selectedLevel) => {
    this.setState({ selectedLevel });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handleRewardAmountChange = (event) => {
    this.setState({ rewardAmount: event.target.value });
  };

  handleCommentsChange = (event) => {
    this.setState({ comments: event.target.value });
  };

  toggleCategory = () => {
    this.setState((prevState) => ({ categoryIsOpen: !prevState.categoryIsOpen }));
  };

  getTitle = () => {
    const { selectedEmployees, owner, isPrefilled } = this.state;

    // else if (isPrefilled && owner) {
    //   return owner;
    // }11
    if (selectedEmployees.length === 1) {
      return selectedEmployees[0].label;
    } else if (selectedEmployees.length > 1) {
      return "Multiple";
    }  else {
      return "New Recognition";
    }
  };

  closeRecognitionModal = (fetchData = true) => {
    this.setState({ recognitionModalOpen: false });

    if(fetchData) 
    this.props.updateItems();
  }

  openRecognitionModal = () => {
    this.setState({ recognitionModalOpen: true });
  }

  handleSubmit = async () => {
    const { selectedEmployees, selectedType, selectedLevel, rewardAmount, economicReward, executionRes, comments, file, selectedDate, load } = this.state;

    if (load !== 0 && load !== 100) {
      Swal.fire('Please wait until the attachment has finished uploading.');
      return;
    }

    // Check if all required fields are filled
    if (!selectedEmployees.length) {
      Swal.fire("Please select employee(s) for the recognition reward.");
      return;
    }
    if (!selectedType) {
      Swal.fire("Please select the type for the recognition reward.");
      return;
    }
    if (!selectedLevel) {
      Swal.fire("Please select the level for the recognition reward.");
      return;
    }
    if (!selectedDate) {
      Swal.fire("Please select the target date for the recognition reward.");
      return;
    }
    if (!executionRes) {
      Swal.fire("Please select the activity owner for the recognition reward.");
      return;
    }
    if (selectedType === "reward" && economicReward && (!rewardAmount || isNaN(rewardAmount))) {
      Swal.fire("Please add a valid amount for the recognition reward. E.g. 9000");
      return;
    }

    // Prepare the data
    const data = {
      level: selectedLevel.value,
      ownerId: executionRes.value.id,
      type: selectedType,
      employeeIds: selectedEmployees.map((emp) => emp.value.id),
      economicReward: economicReward ? "true" : "false",
      amountReward: rewardAmount || 0,
      comments, // Added comments
      attachment: file, // Added file
      selectedDate,
      recognitionId: this.props.recognition.id || -1
    };

    try {
      if (this.props.recognition.id) {
        // Prefilled: use PATCH to update the recognition
        const recognitionId = this.props.opportunityId; // Assuming recognitionId is available from props

        await updateRecognitionRewardById(recognitionId);
        Swal.fire("Recognition updated successfully.");
      } else {
        // Not prefilled: use POST to submit a new recognition
        await submitRecognitionReward(this.props.opportunityId, data);
      }
      
      this.props.updateItems()
      this.props.toggleModal()
    } catch (error) {
      Swal.fire(`Error submitting the recognition reward: ${error.message || "Something went wrong."}`);
    }
  };

  handleDelete = async (deleteFromDb) => {
    try {
      if (deleteFromDb) {
        // Prefilled: use PATCH to update the recognition
        const opportunityId = this.props.opportunityId;
        const recognitionId = this.props.recognition.id; // Assuming recognitionId is available from props
        await deleteRecognizeById(opportunityId, recognitionId);
        this.props.removeRecognitionItem();
      } else {
        this.props.removeRecognitionItem();
      }

      this.openRecognitionModal();
    } catch (error) {
      Swal.fire(`Error submitting the recognition reward: ${error.message || "Something went wrong."}`);
    }
  };

  changeResponsible = (res, idx) => {
    this.setState({
      executionRes: res,
    });
  };

  finishRecognition = async () => {
    try {
      const recognitionId = this.props.opportunityId; 
      await updateRecognitionRewardById(recognitionId, {});
      this.closeRecognitionModal()
      window.location.href = '/reward-recognize'
    } catch (error) {
      Swal.fire(error.message)
    }
  };


  getSelectedLevel = (selectedLevel) => {
    if (selectedLevel === 'level1') {
      return "Level 1";
    } else if (selectedLevel === 'level2') {
      return "Level 2";
    } else if (selectedLevel === 'level3') {
      return "Level 3";
    }
  }

  getTargetDate = (selectedDate) => {
    const dateObject = new Date(selectedDate);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return dateObject.toLocaleDateString('en-GB', options);
  };



  render() {
    const { modalVisible, attachment, economicReward, selectedType, categoryIsOpen, selectedEmployees, rewardAmount, selectedDate, selectedLevel, isPrefilled, comments, executionRes } = this.state;

    const optionsType = [
      { value: "recognition", label: "Recognition" },
      { value: "reward", label: "Reward" },
    ];
    const optionsLevel = [
      { value: "level1", label: "Level 1" },
      { value: "level2", label: "Level 2" },
      { value: "level3", label: "Level 3" },
    ];

    const customSelectStyles = {
      menu: (provided) => ({ ...provided, zIndex: 9999 }),
      control: (provided) => ({ ...provided, zIndex: 9999 }),
    };

    return (
      <>
        <Row>
          <Col lg="12" className="m-auto">
            <div
              style={{
                width: "100%",
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: 2,
                borderRadius: 5,
                marginBottom: 5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="ml-4" style={{ height: "100%", display: "inline-block" }}>
                <h6 className="my-auto" style={{ fontWeight: 500, color: "#303030" }}>
                  {this.getTitle()}
                </h6>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {selectedType && (
                  <h6 className="my-auto mr-4" style={{ fontWeight: 500, color: "#303030" }}>
                    {selectedType === "recognition" ? "Recognition" : "Reward"}
                  </h6>
                )}

                {categoryIsOpen ? (
                  <ArrowUpIcon
                    onClick={this.toggleCategory}
                    className="mr-2 ml-2"
                    style={{ height: 16, width: 16, display: "inline-block" }}
                  />
                ) : (
                  <ArrowDownIcon
                    onClick={this.toggleCategory}
                    className="mr-2 ml-2"
                    style={{ height: 16, width: 16, display: "inline-block" }}
                  />
                )}
              </div>
            </div>

            {categoryIsOpen && (
              <div style={{ backgroundColor: "#F6F6F6", padding: "10px", borderRadius: 5 }}>
                {isPrefilled ? (
                  <>
                    <Row className="mx-4">
                      <Col md="6">
                        <label><strong>Employee(s):</strong></label>
                        <h6>{selectedEmployees.map(emp => emp.label).join(" ▪ ")}</h6>
                      </Col>
                      <Col md="3">
                        <label><strong>Type:</strong></label>
                        <h6>{selectedType === "recognition" ? "Recognition" : "Reward"}</h6>
                      </Col>
                      <Col md="3">
                        <label><strong>Level:</strong></label>
                        <h6>{selectedLevel ? this.getSelectedLevel(selectedLevel) : "N/A"}</h6>
                      </Col>
                    </Row>
                    <Row className="mx-4">
                      <Col md="6">
                        <label><strong>Target Date:</strong></label>
                        <h6>{selectedDate ? this.getTargetDate(selectedDate) : "N/A"}</h6>
                      </Col>
                      <Col md="6">
                        <label><strong>Attachments:</strong></label>
                        {!attachment && <h6>No attachments</h6>}
                        {attachment && (
                          <Button pill style={{ display: 'flex' }} onClick={() => this.setState({ modalVisible: !modalVisible })}>View Attachment</Button>
                        )}
                      </Col>
                    </Row>
                    <Row className="mx-4">
                      <Col md="6">
                        <label><strong>Activity Owner:</strong></label>
                        <h6>{executionRes ? executionRes.label : "N/A"}</h6>
                      </Col>
                      <Col md="6">
                        <label><strong>Comments:</strong></label>
                        <h6>{comments || "N/A"}</h6>
                      </Col>
                    </Row>
                    {selectedType == 'reward' &&
                      <Row className="mx-4">
                        <Col md="6">
                          <label><strong>Economic Reward?</strong></label>
                          <h6>{economicReward ? "Yes" : "No"}</h6>
                        </Col>
                        <Col md="6">
                          <label><strong>Reward Amount:</strong></label>
                          <h6>${rewardAmount == 0 ? '0' : rewardAmount}</h6>
                        </Col>
                      </Row>
                    }
                    {this.props.recognition.id && !this.props.finishedRecognize && <Row>
                      <Col className="d-flex justify-content-end mt-3" md="12">
                        <CancelIcon
                          className="functionalButton"
                          style={{ height: 34, width: 34 }}
                          onClick={() => this.handleDelete(true)} // Remove item on click
                        />
                      </Col>
                    </Row>}
                  </>
                ) : (
                  <>
                    <Row className="mx-4 my-4">
                      <Col md="6">
                        <label>Select Employee(s): *</label>
                        <ExecutionSelectNew
                          className="insideFont"
                          evalType="recognition"
                          setResponsible={(res) => this.setState({ selectedEmployees: res })}
                          selectedVal={selectedEmployees}

                        />
                      </Col>
                      <Col md="3">
                        <label>Select Type: *</label>
                        <Select
                          options={optionsType}
                          placeholder="Select Type"
                          onChange={this.handleTypeChange}
                          value={optionsType.find(option => option.value === selectedType)}
                          styles={customSelectStyles}
                        />
                      </Col>
                      <Col md="3">
                        <label>Select Level: *</label>
                        <Select
                          options={optionsLevel}
                          placeholder="Select Level"
                          onChange={this.handleLevelChange}
                          value={selectedLevel ? optionsLevel.find(option => option.value === selectedLevel.value) : null}
                          styles={customSelectStyles}
                        />
                      </Col>
                    </Row>

                    <Row className="mx-4 my-4">
                      <Col md="4">
                        <div><label>Select Target Date: *</label></div>
                        <DatePicker
                          placeholderText={selectedDate ? selectedDate : 'Select Date'}
                          dropdownMode="select"
                          className="text-center full-width-date"
                          required
                          onChange={this.handleDateChange}
                        />
                      </Col>
                      <Col md="8">
                        <label>Add Attachments:</label>
                        <CustomFileWithProgressUpload
                          maximumSizeFile={10}
                          onProgressUpdate={(progress) => {
                            this.setState({ load: progress });
                          }}
                          onFileUpload={(file) => {
                            this.setState({ file })
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="mx-4 my-4">
                      <Col md="4">
                        <label>Activity Owner: *</label>
                        <ExecutionSelectNew
                          className="insideFont"
                          evalType="execution"
                          setResponsible={(res, idx) => this.changeResponsible(res, idx)}
                          selectedVal={executionRes}
                        />
                      </Col>
                      <Col md="8">
                        <label>Add Comments:</label>
                        <FormInput
                          value={comments}
                          onChange={this.handleCommentsChange}
                          placeholder="Add Comments"
                        />
                      </Col>
                    </Row>

                    {selectedType === "reward" && (
                      <>
                        <Row className="mx-4">
                          <Col md="4">
                            <label>Economic Reward?</label>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  value="yes"
                                  checked={economicReward}
                                  onChange={() => this.setState({ economicReward: true })}
                                />
                                Yes
                              </label>
                              <label style={{ marginLeft: "20px" }}>
                                <input
                                  type="radio"
                                  value="no"
                                  checked={!economicReward}
                                  onChange={() => this.setState({ economicReward: false })}
                                />
                                No
                              </label>
                            </div>
                          </Col>
                          {this.state.economicReward && <Col md="8">
                            <label>Add Reward Amount:</label>
                            <FormInput
                              value={rewardAmount}
                              onChange={this.handleRewardAmountChange}
                              placeholder="Please add Reward details below"
                            />
                          </Col>}
                        </Row>
                      </>
                    )}
                    
                    <Row style={{ display: "flex" }}>
                    <Col className="d-flex mt-3" md="10">
                      <ResponseRequired />
                      </Col>
                    <Col className="d-flex justify-content-end mt-3" md="2">
                      <AcceptIcon
                        className="functionalButton"
                        style={{ height: 34, width: 34, marginRight: 10 }}
                        onClick={this.handleSubmit} // Call the submit function
                      />
                      <CancelIcon
                        className="functionalButton"
                        style={{ height: 34, width: 34 }}
                        onClick={() => this.handleDelete(false)} // Remove item on click
                      />
                    </Col>
                  </Row>
              </>
            )}
          </div>
          )}
        </Col>
      </Row >
        { attachment && <IdeaAttachmentModal ideaItem={attachment} onClick={() => this.setState({ modalVisible: !modalVisible })} isVisible={modalVisible} file={attachment} type="file" />
  }
      </>
    );
  }
}

export default RecognitionItem;

import moment from 'moment';
import Parse from 'parse';
import React from "react";
import { withTranslation } from "react-i18next";
import {
  Badge,
  Card,
  CardBody,
  Container
} from "shards-react";
import { ReactComponent as GreenIcon } from "../../images/green_icon.svg";
import { ReactComponent as RedIcon } from "../../images/red_icon.svg";
import { getUpcomingTasks } from '../../services/implementService';

class UpcomingDeadlinesEvents extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      nextSteps:[],
      title:''
    }
  }

  componentDidMount() {
    this.fetchNextSteps();
    
  }

  async fetchNextSteps() {
    const upcomingTasks = await getUpcomingTasks()
    
    this.setState({ nextSteps: upcomingTasks });
  }


   updateNextStepsIfIdeaCompleted() {
    const IdeaClass = Parse.Object.extend("Idea");
    const NextStepClass = Parse.Object.extend("NextSteps");

    // Create a query for ideas with progress[0] == 100
    const ideaQuery = new Parse.Query(IdeaClass);
    ideaQuery.equalTo("progress.0", 100);

    ideaQuery.find().then((ideas) => {
        // For each idea, find corresponding NextSteps and update them
        ideas.forEach((idea) => {
            const ideaNum = idea.get("num");
            const nextStepQuery = new Parse.Query(NextStepClass);
            nextStepQuery.equalTo("ideaNum", ideaNum);
            nextStepQuery.equalTo("completed", false);

            nextStepQuery.find().then((nextSteps) => {
                nextSteps.forEach((nextStep) => {
                    nextStep.set("completed", true);
                    nextStep.set("customUpdatedAt", new Date());
                    nextStep.save();
                });
            }).catch((error) => {
                console.error("Error updating NextSteps: ", error);
            });
        });
    }).catch((error) => {
        console.error("Error finding Ideas: ", error);
    });
}


  showModal(item) {
    this.props.onEvalBtnPress(item);
  }

  removeTasksForCompletedIdeas() {
    const nextSteps = this.state.nextSteps; // Assuming you have access to the current state
    
    nextSteps.forEach(async (nextStep) => {
        const ideaNum = nextStep.get("ideaNum");

        const IdeaClass = Parse.Object.extend("Idea");
        const ideaQuery = new Parse.Query(IdeaClass);

        ideaQuery.equalTo("num", ideaNum);
        ideaQuery.limit(1);

        try {
            const idea = await ideaQuery.first();

            if (idea) {
                const ideaProgress = idea.get("progress");

                if (ideaProgress && ideaProgress.length > 0 && ideaProgress[0] === 100) {
                    nextStep.set("completed", true);
                    nextStep.set("customUpdatedAt", new Date());
                    await nextStep.save();
                    
                }
            }
        } catch (error) {
            console.error("Error while processing idea:", error);
        }
    });
}


  render() {
    const {nextSteps} = this.state;
    const { t } = this.props;

    return(
      <Card>
        <CardBody className="p-0">
          <Container fluid className="px-0">
            <div style={{ height: "220px", overflowY: "scroll", position: "relative" }}>
              <table className="table mb-0">
                <thead className="py-2 bg-light text-semibold border-bottom">
                  <tr>
                    <th className="text-center">{t("IDEA_MANAGE_NUM")}</th>
                    <th className="text-center">{t("Activity Description")}</th>
                    <th className="text-center">{t("Deadline")}</th>
                    <th className="text-center">{t("Status")}</th>
                  </tr>
                </thead>
                  <tbody>
                    {nextSteps.length > 0 && nextSteps.map((item, idx) => (
                      <tr key={idx}>
                        <td className="lo-stats__total text-center">
                          <Badge pill onClick={() => this.showModal(item)}>
                            {item.opportunity.num}
                          </Badge>
                        </td>
                        <td className="lo-stats__items text-center">{item.description}</td>
                        <td className="lo-stats__items text-center">
                          {item.deadline ? moment(item.deadline).format("DD MMMM YYYY"): "N/A"}
                        </td>
                        <td className="lo-stats__items text-center">
                          <div style={{ height: 33, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {new Date(new Date(item.deadline).setHours(0, 0, 0, 0)) >= new Date(new Date().setHours(0, 0, 0, 0)) ? (
                              <GreenIcon
                                style={{ height: 20, width: 20 }}
                                onClick={() => this.showModal(item)}
                              ></GreenIcon>
                            ) : (
                              <RedIcon
                                style={{ height: 20, width: 20 }}
                                onClick={() => this.showModal(item)}
                              ></RedIcon>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    {
                      nextSteps.length === 0 && <span style={{position: "absolute", top: "50%", left: "calc(50% - 85px)", color: "rgba(0, 0, 0, 0.5)"}}>No deadlines found</span>
                    }
                  </tbody>
              </table>
            </div>
          </Container>
        </CardBody>
      </Card>
    )
  }
}


export default withTranslation()(UpcomingDeadlinesEvents);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "shards-react";
import Swal from 'sweetalert2';
import { reassignCoach, reassignImplementationLeader } from '../../services/implementService';
import { authUser } from "../../services/userService";
import ExecutionSelectNew from "../common/ExecutionSelectNew";

function ReassignOwnerCoach({ currIdea }) {
  const { t } = useTranslation();
  const [executionRes, setExecutionRes] = useState('');
  const [executionCoach, setExecutionCoach] = useState([]);
  const [saveCoaches, setSaveCoaches] = useState([]);
  const currentUser = authUser();
  const isSuperUser = currentUser.roles.includes("super_user") || currentUser.roles.includes("murmuratto")

  const changeResponsible = (res) => {
    setExecutionRes(res);
  }

  const changeCoach = (res) => {
    if (res && res.length > 3) {
      Swal.fire("Error", "You can only select up to 3 coaches", "error");
      return;
    }

    setExecutionCoach(res);
    
    let setCoaches = null;
    if(res){
      setCoaches = res.map(option => option.value);
    }
    
    setSaveCoaches(setCoaches);
  }

  const saveCoach = async () => {
    try {
      if (saveCoaches && saveCoaches.length > 0) {
        await reassignCoach(currIdea.id, saveCoaches);
        Swal.fire("Success", "Change completed. Please refresh the page to see the updated information.", "success");
        setExecutionCoach([]);
        setSaveCoaches([]);
      } else {
        Swal.fire("Error", "Please select a valid coaches", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error"); 
    }
  }

  const changeOwner = async () => {
    try {
      const leader = executionRes.value;

      if(leader.id){
        await reassignImplementationLeader(currIdea.id, leader.id);
        Swal.fire("Success", "Change completed. Please refresh the page to see the updated information.", "success");
        setExecutionRes('');
      }else{
        Swal.fire("Error", "Please select a valid implementation leader", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  }

  if (!isSuperUser ) return null;

  return (
    <Row className="mt-4" md="12">
      <Col md="3">
        <label htmlFor="firstName" className="georgia">{t('REASSIGN')} {t('IMPLEMENTATION_LEADER')}</label>
        <ExecutionSelectNew 
          className="insideFont" 
          evalType={'execution'} 
          setResponsible={(res) => changeResponsible(res)} 
          selectedVal={executionRes}
        />
        {executionRes && <Button className="ml-auto mt-3" pill onClick={() => changeOwner()}>Save New Owner</Button>}
      </Col>
      <Col md="3">
        <label htmlFor="firstName" className="georgia">{t('REASSIGN')} Coach</label>
        <ExecutionSelectNew 
          className="insideFont" 
          evalType={'coach'} 
          setResponsible={(res) => changeCoach(res)} 
          selectedVal={executionCoach}
        />
        {executionCoach.length ? <Button className="ml-auto mt-3" pill onClick={() => saveCoach()}>Save New Coach</Button> : ''}
      </Col>
    </Row>
  );
}

export default ReassignOwnerCoach;

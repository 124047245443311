import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "shards-react";
import LoginFormContainer from "../components/auth/LoginFormContainer";
import MurmurattoLogo from '../images/Murmuratto_With-Icon_Purple.png';
import { authUser, getSubdomain } from "../services/userService";

class Login extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const currentUser = authUser();
    if(currentUser) return <Redirect to="/submit" />;
    const subdomain = getSubdomain() || 'demo';

    const imageUrl = `${process.env.REACT_APP_EP_API}/public/company-logo/${subdomain}?_122`;
    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100 flex-center">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Row className="flex-center mt-5 mb-5">
              <img
                id="main-logo"
                className="align-top"
                style={{ maxWidth: "90%", maxHeight: '90px', marginBottom: '20px', margin: '0 auto 20px auto' }}
                src={imageUrl}
                alt="Murmuratto"
              />
            </Row>
            <Card>
              <CardBody>
                <h5 className="auth-form__title text-center mb-4">
                  Access Your Account
                </h5>
                <LoginFormContainer handleAuth={this.props.handleAuth} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="d-table m-auto mt-3">
          <br />
          <small>powered by</small>
          <br />
          <a href="#">
            <img
              id="main-logo"
              className="d-inline-block align-top mr-1 ml-3"
              style={{ maxWidth: "140px" }}
              src={MurmurattoLogo}
              alt="Murmuratto"
            />
          </a>
        </div>
      </Container>
    );
  }
}

export default Login;

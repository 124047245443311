import { saveAs } from 'file-saver';
import moment from 'moment';
import PropTypes from "prop-types";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Row } from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import verifyService from "../../services/verifyInboxService";
import colors from "../../utils/colors";
import IdeaAttachmentModal from '../common/IdeaAttachmentModal';
import ReassignOwnerCoach from "../components-overview/ReassignOwnerCoach";

function OriginalIdeaInfoCompleted({ user, open, currIdea, setCategory, openIdea, openIdeaA3, stage, openVerification, openRecognition, view,historyEvaluate  }) {
  const { t } = useTranslation();
  const [categoryIsOpen, setCategoryIsOpen] = useState(open);
  const [modalVisible, setModalVisible] = useState(false);
  const [opportunity, setOpportunity] = useState(currIdea);

  const handleCategoryOpen = (isOpen) => {
    setCategoryIsOpen(isOpen);
  };


  const getIdeaType = () => {
    return opportunity.type;
  };

  const getCoach = () => {
    if (opportunity.coaches && opportunity.coaches.length > 0) {
      return opportunity.coaches.join(" & ");
    } else {
      return "N/A";
    }
  };

  const getSubmittedDate = () => {
    if (opportunity.submittal && opportunity.submittal.submittedAt) {
      return moment(opportunity.submittal.submittedAt).format("DD MMMM YYYY");
    } else {
      return "N/A";
    }
  };

  const getTargetDate = () => {
    const targetDate = opportunity.completionTargetDate;

    if (targetDate) {
      return moment(targetDate).format("DD MMMM YYYY");
    } else {
      return "N/A";
    }
  };

  const getReviewedDate = () => {
    if (opportunity.reviewedAt) {
      return moment(opportunity.reviewedAt).format("DD MMMM YYYY");
    } else {
      return "N/A";
    }
  };

  const getStartedDate = () => {
    const targetDate = opportunity.startedAt;

    if (targetDate) {
      return moment(targetDate).format("DD MMMM YYYY");
    } else {
      return "N/A";
    }
  };


  const getTeamMembers = () => {
    const teamMemberObjs = opportunity.teamMembers;

    if (teamMemberObjs) {
      return teamMemberObjs.join(" • ");
    } else {
      return "N/A";
    }
  };
  
  const handleDownloadVerifyReport = async () => {
    try {
      const response = await verifyService.downloadReport(currIdea.id);
      const { fileData } = response;

      if (!fileData) return;

      const byteCharacters = atob(fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });

      const currentDate = new Date().toISOString().slice(0, 10);
      const downloadFileName =  `report_verify_${currentDate}.xlsx`;

      saveAs(blob, downloadFileName);
    } catch (error) {
      console.error('Error al descargar los datos:', error);
    }
  }  
  
  return (
  <Row>
      <Col lg="12" className="m-auto">
        { view === 'verify-history' && 
          <ButtonGroup size="sm" style={
            {
              display: 'block',
              width: 'max-content',
            }
          } className="mx-auto mb-4">
            <Button pill theme="white" onClick={() => handleDownloadVerifyReport()}>
              Download Data
            </Button>
          </ButtonGroup> }


        <div style={{ width: '100%', borderStyle: 'solid', borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex' }}>
          <div className="ml-4 my-auto" style={{ height: '100%', display: 'inline-block' }}>
            <h6 className="my-auto" style={{ fontWeight: 500, color: '#303030' }}>
              {t("IdeaDetails") + " : #" + opportunity.num + " " + opportunity.title}
            </h6>
          </div>

          {categoryIsOpen ? (
            <ArrowUpIcon onClick={() => handleCategoryOpen(false)} className="mr-2 ml-auto my-auto" style={{ height: 16, width: 16, display: 'inline-block' }} />
          ) : (
            <ArrowDownIcon onClick={() => handleCategoryOpen(true)} className="mr-2 ml-auto my-auto" style={{ height: 16, width: 16, display: 'inline-block' }} />
          )}
        </div>


        {categoryIsOpen && (
          <div className="mt-2" style={{ backgroundColor: '#F6F6F6' }}>
            <Row form className="mx-4 mt-2">
              <Col lg="12" className="mt-2">
                <Row className="mt-4">
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">{t('creator')}</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{opportunity.proponent}</h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Title</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{opportunity.title}</h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Category</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{opportunity.category.name}</h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Type</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{getIdeaType()}</h6>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Date Submitted</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>
                      {getSubmittedDate()}
                    </h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Date Reviewed</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{getReviewedDate()}</h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Start Date</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{getStartedDate()}</h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Decision</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{opportunity.decision || "N/A"}</h6>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Target Completion Date</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{getTargetDate()}</h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Implementation Leader</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{opportunity.implementationLeader || "N/A"}</h6>
                  </Col>
                  <Col md="3">
                    <label htmlFor="firstName" className="georgia">Coach</label>
                    <h6 style={{ fontWeight: 500, color: '#303030' }}>{getCoach()}</h6>
                  </Col>
                </Row>
              </Col>
              {opportunity.teamMembers && opportunity.teamMembers.length > 0 && (
                <Col lg="12" className="mt-2">
                  <Row className="mt-4">
                    <Col md="12">
                      <label htmlFor="firstName" className="georgia">Team Members</label>
                      <h6 style={{ fontWeight: 500, color: '#303030' }}>{getTeamMembers()}</h6>
                    </Col>
                  </Row>
                </Col>
              )}

              {historyEvaluate &&  
              <Col lg="12" className="mb-4">
                <ReassignOwnerCoach 
                  currIdea={currIdea}
                />
              </Col>
              }
              <Col lg="12" className="mt-2">
                <Row className="mb-4">

                  <Col md="12" className="text-right" style={{display: "flex"}}>
                  {opportunity.evaluation && opportunity.evaluation.attachment && (
                      <Button pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8", alignSelf: "flex-start", }} onClick={() => setModalVisible(true)}>
                        Evaluation Attachment
                      </Button>
                    )}
                    <Button className="ml-auto" pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8" }} onClick={() => openIdea()}>
                      {t("SubmittalDetails")}
                    </Button>
                    {(opportunity.implement) && (opportunity.status !== 'Duplicate') &&
                    <Button className="ml-2" pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8" }} onClick={() => openIdeaA3()}>
                            {t("ImplementationDetails")}
                    </Button> }
                    {(stage === 'verified' || stage === 'recognized') && (opportunity.status !== 'Duplicate') &&(
                      <Button className="ml-2" pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8" }} onClick={() => openVerification()}>
                        {t("VerificationDetails")}
                      </Button>
                    )}
                    {stage === 'recognized' && (opportunity.status !== 'Duplicate') && (
                      <Button className="ml-2" pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8" }} onClick={() => openRecognition()}>
                        {t("RecognitionDetails")}
                      </Button>
                    )}


                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Col>
      {opportunity.evaluation && opportunity.evaluation.attachment && (
        <IdeaAttachmentModal ideaItem={opportunity.evaluation.attachment} onClick={setModalVisible} isVisible={modalVisible} type="evaluationFile" />
      )}
    </Row>
  );
}

OriginalIdeaInfoCompleted.propTypes = {
  smallStats: PropTypes.array
};

OriginalIdeaInfoCompleted.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default OriginalIdeaInfoCompleted;

import moment from 'moment';
import React from "react";
import { withTranslation } from "react-i18next";
import ReactLoading from 'react-loading';
import ReactTable from "react-table";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import { getInbox } from '../../services/implementService';
import SingleProgressChart from "../analytics/GoalsOverview/SingleProgressChart";

class IdeasUnderImplementation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ideas: [],
      isLoading: false,
      title: '',
      responsibleIdeas: [],
      coachIdeas: [],
      pageSize: 5,
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      search: ''
    };

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentDidMount() {
    this.fetchIdeas();
  }

  async fetchIdeas() {
    const { isLoading } = this.state;
    if (isLoading) return;
    this.setState({ isLoading: true });
    const inbox = await getInbox();

    this.setState({ ideas: inbox, isLoading: false });
  }

  handlePageSizeChange(e) {
    this.setState({ pageSize: parseInt(e.target.value) });
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm });
  }

  showModal(item) {
    this.props.onEvalBtnPress(item);
  }

  render() {
    const { isLoading, pageSize, pageSizeOptions, search, ideas } = this.state;
    const { t, filter } = this.props;


    let filteredIdeas = ideas

    if (filter === 'Coach') {
      filteredIdeas = ideas.filter(idea => idea.isCoach);
    } else if (filter === 'Owner') {
      filteredIdeas = ideas.filter(idea => !idea.isCoach);
    }



    if (search) {
      filteredIdeas = ideas.filter(idea =>
        idea.title.toLowerCase().includes(search) ||
        idea.num.toString().includes(search)
      );
    }

    const columns = [
      {
        Header: t("IDEA_MANAGE_NUM"),
        accessor: "num",
        className: "text-center",
        maxWidth: 120,
      },
      {
        Header: t("IDEA_MANAGE_TITLE"),
        accessor: "title",
        className: "text-center",
        Cell: row => row.original.closed ?
          (<div>{row.value}<span style={{
            padding: "6px 8px",
            backgroundColor: "rgb(0, 123, 255)",
            color: "#fff",
            borderRadius: "30px",
            marginLeft: "5px",
            fontSize: "9.75px"
          }}>
            Closed
          </span></div>)
          :
          (<div>{row.value}</div>),
      },
      {
        Header: t("Owner"),
        maxWidth: 120,
        accessor: "owner",
        className: "text-center",
      },
      {
        Header: t("Days Open"),
        maxWidth: 120,
        accessor: "daysOpen",
        className: "text-center",
      },
      {
        Header: t("IDEA_MANAGE_PROG"),
        accessor: "progress",
        maxWidth: 150,
        className: "text-center",
        Cell: row => <SingleProgressChart idea={row.original.actions} />,
      },
      {
        Header: 'Action',
        accessor: "actions",
        className: "text-right",
        sortable: false,
        width: 80,
        Cell: row => (
          <Badge style={{ cursor: 'pointer' }} outline pill theme="secondary" onClick={() => this.showModal(row.original.actions)}>
            View
          </Badge>
        ),
      }
    ];

    const data = filteredIdeas.map(idea => ({
      num: idea.num,
      title: idea.title,
      owner: idea.owner,
      daysOpen: idea.daysOpen,
      progress: idea.progress,
      actions: idea,
      a3Completed: idea.a3Completed,
      closed: idea.closed
    }));

    return (
      <Card>
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex" md="6">
                <span>{t('SHOW')}</span>
                <FormSelect
                  size="sm"
                  value={pageSize}
                  onChange={this.handlePageSizeChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size + ' ' + t('ROWS')}
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col className="file-manager__filters__search d-flex" md="6">
                <InputGroup seamless size="sm" className="ml-auto">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput value={search} onChange={this.handleFilterSearch} />
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <Container fluid className="px-0">
            {isLoading ? (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
              </div>
            ) : (
              <ReactTable
                columns={columns}
                data={data}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                showPageSizeOptions={false}
                resizable={false}
              // className="-striped -highlight"
              />)}
          </Container>
        </CardBody>
      </Card>
    )
  }
}

export default withTranslation()(IdeasUnderImplementation);

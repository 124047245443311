import axios from 'axios';
import { authUser } from './userService'; 

const API_URL =  process.env.REACT_APP_EP_API

export const fetchInbox = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/workflow/evaluate?context=inbox`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchHistory = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/workflow/evaluate?context=history`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchOpportunityById = async (opportunityId) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/opportunity/${opportunityId}?viewFrom=evaluate`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching departments:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const submitEvaluation = async (data) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    const formData = new FormData();
  
    // Conditionally append each field only if it has a value
    if (data.action) formData.append("action", data.action);
    if (data.reason) formData.append("reason", data.reason);
    if (data.question) formData.append("question", data.question);
    if (data.likelyToApproveInFuture) formData.append("likelyToApproveInFuture", data.likelyToApproveInFuture);
    if (data.fileId) formData.append("fileId", data.fileId);
    if (data.economicImpactAmount) formData.append("forecast[economicImpactAmount]", data.economicImpactAmount);
    if (data.impactTime) formData.append("forecast[impactTime]", data.impactTime);
    if (data.recurringImpact) formData.append("forecast[recurringImpact]", data.recurringImpact);
    if (data.committeeId) formData.append("committeeId", data.committeeId);
    if (data.implementationLeaderId) formData.append("implementationLeaderId", data.implementationLeaderId);
    if (data.coachIds) formData.append("coachIds", data.coachIds);
  
    try {
      const response = await axios.post(
        `${API_URL}/workflow/evaluate/${data.opportunityId}`, 
        formData, 
        { headers: authHeader }
      );
      return response.data;
    } catch (error) {
      console.error('Error submitting evaluation:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  export const markAsNotDuplicate = async (opportunityId, similarId) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    const formData = new FormData();
    formData.append('similarId', similarId);
  
    try {
      const response = await axios.post(
        `${API_URL}/workflow/evaluate/not-duplicate/${opportunityId}`,
        formData,
        { headers: authHeader }
      );
      return response.data;
    } catch (error) {
      console.error('Error marking as not duplicate:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  export const markDuplicate = async (opportunityId) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { Authorization: `Bearer ${accessToken}` };
  
    try {
      const response = await axios.post(
        `${API_URL}/workflow/evaluate/duplicate/${opportunityId}`,
        {},
        { headers: authHeader }
      );
      return response.data;
    } catch (error) {
      console.error('Error marking as duplicate:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  export const markNotDuplicate = async (opportunityId) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { Authorization: `Bearer ${accessToken}` };
  
    try {
      const response = await axios.post(
        `${API_URL}/workflow/evaluate/not-duplicate/${opportunityId}`,
        {},
        { headers: authHeader }
      );
      return response.data;
    } catch (error) {
      console.error('Error marking as not duplicate:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
import Parse from 'parse';
import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormInput,
  FormRadio,
  FormTextarea,
  Row,
} from "shards-react";

import { object } from "prop-types";
import { withTranslation } from 'react-i18next';
import { ReactComponent as BarsImage } from "../../images/bars.svg";
import { ReactComponent as DocumentImage } from "../../images/document.svg";
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as LineChartImage } from "../../images/line-chart.svg";
import { ReactComponent as RibbonImage } from "../../images/ribbon.svg";
import IdeaAttachmentModal from '../common/IdeaAttachmentModal';
import FormSectionTitle from "../edit-user-profile/FormSectionTitle";


class IdeaVerificationViewNew extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          data:[],
          ideaQuestions: [],
          answers:[],
          modalVisible: false,
          category:null,
          department:null,
          date: '',
          categoryQuestions: [],
          filterQuestions: [],
          selectedFilterQ: [],
          filterQAnswers: [],
          categoryQAnswers: [],
          ideaDescription: null,
          file:null, 
          buttonNext:true,
          descriptionValid:'',
          remainingCharacters: 250,
          visible: true,
          filterVisible: false,
          filterQuestionsVisible: false, 
          ideaQuestionsVisible: false,
          hideNextButton: false,
          userName: 'User Name',
          sectionTitle:'',
          formButtonTitle: 'Continuar',
          ideaNumber: '#00008',
          status: '',
          executionRes: 0,
          executionResObj: object,
          selectedBenefit:'',
          selectedResult: '',
          money: '',
          selectedImprovement: '',
          selectedImpact: '',
          selectedCoachBackup: '',
          isRecognized: '',
          aVerification: ''
        }

        this.change = this.change.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setIdeaDescription = this.setIdeaDescription.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.showNext = this.showNext.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.showNext = this.showNext.bind(this);
    }
    


    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        //  
        //  
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          //  
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        //  
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions.");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
      //  
      //  
      //  
      //  
      //  
    }

    resetIdeaForm() {
      window.location.reload();
    }


    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions, selectedFilterQ } = this.state;
      //  
      //  
      
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      //  
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
      // this.setState({category: event.target.value});
      this.setState({department: event.target.value});
      //  
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
      //  
    }

    setFilter(filterName) {
      //  
      // this.setState({sectionTitle: (filterName === "innovacion")?"INNOVACION":"RESOLUCION DE PROBLEMAS"});
      this.handleFilterChange(filterName);
    }

    clickedPrint() {
       
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
      //  
    }

    filterQuestionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.filterQAnswers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    questionAnswerChange(event, idx) {
      this.state.answers[idx].answer = event.target.value;
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

      //  
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      //  
    }

    selectFile(file) {
      //  
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        //  
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
      //  
    }

    addFilterAnswer(filteredData) {
      //  
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          //  
        });
    }

    changeStatus(selection) {
      this.setState({status: selection});
       
    }

    changeResponsible(res, idx) {
       
      this.setState({
        executionResObj: res,
        executionRes: idx
      });
       
    }

    async getHumanResourceUsers() {
      var query = new Parse.Query(Parse.User);
      query.equalTo("humanResources", true);
      const results = await query.find();
       
      return results
    }

    async setVerificationNotifications() {
    }

    render() {
      const {visible, modalVisible } = this.state
      const {ideaItem} = this.props
      const aVerification = ideaItem.verify
      const formVisibilityState = visible? 'block' : 'none';
      let hasResults = ''
      let hasImprovements = ''
      let hasImpacts = ''
      let fiResult = ''
      let comments = null
      let hasFile = false
      console.log('aVerification',aVerification)
      if (aVerification) {
        hasResults = aVerification.goalsMet
        hasImprovements = aVerification.processImprovement
        hasImpacts =  aVerification.replicationPotential
        fiResult = aVerification.financialImpact
        comments = aVerification.additionalComments
        hasFile = aVerification.attachment
      }

      const setModalVisible = (isVisible) => {
        this.setState({modalVisible: isVisible})
      }

        const { t } = this.props;
        return(
                  <>
                  <Row md="12" >
                    <Col md="12" >
                    </Col>
                    <Col md="12">
                    <Row className="mt-4">
                        <Col md="12" lg="12">
                    <Card small className="edit-user-details mb-4">
                    {/* <ProfileBackgroundPhoto /> */}
    
                    <CardBody className="p-0">
    
                      {/* Form Section Title :: General */}
                      <Form className="py-4"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                        
                        {/* VISIBILITY */}
                        <div style={{display: formVisibilityState}}>
                        
    
                        <br/>

                        <FormSectionTitle
                          title={t("EVAL_IDEA_VERIFICATION")}
                          description=""
                        />
                        <Row form className="mx-4">
                        {/* Categoria */}
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><BarsImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("EVAL_IDEA_BENEFITS")}:</p>
                                {hasResults === true ?
                                <FormRadio
                                    inline
                                    name="results"
                                    checked={hasResults === true}
                                    onChange={() => {
                                    this.changeResults(true);
                                    }}
                                >
                                    {t("EVAL_IDEA_YES")}
                                </FormRadio>
                                :
                                <FormRadio
                                    inline
                                    name="results"
                                    checked={hasResults === false}
                                    onChange={() => {
                                    this.changeResults(false);
                                    }}
                                >
                                    {t("EVAL_IDEA_NO")}
                                </FormRadio>
                                }
                                </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                <Row form className="mx-4">
                                <Col md="3"><LineChartImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("EVAL_IDEA_IMPROVEMENT")}:</p>
                                {hasImprovements === true ?
                                <FormRadio
                                    inline
                                    name="improvement"
                                    checked={hasImprovements === true}
                                    onChange={() => {
                                    this.changeImprovement(true);
                                    }}
                                >
                                    {t("EVAL_IDEA_YES")}
                                </FormRadio>
                                :
                              <FormRadio
                                    inline
                                    name="improvement"
                                    checked={hasImprovements === false}
                                    onChange={() => {
                                    this.changeImprovement(false);
                                    }}
                                >
                                    {t("EVAL_IDEA_NO")}
                                </FormRadio>
    }
                                </Col>
                                </Row>
                            </Col>
                            
                             <Col md="4" className="form-group">
                                <Row form className="mx-4">
                                    <Col md="3"><DollarImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                    <Col md="9">
                                        <p className="mb-2">{t("EVAL_IDEA_FINRES")}:</p>
                                        <FormInput onChange={this.changeMoney} disabled value= {fiResult} placeholder="USD $" />
                                    </Col>
                                </Row>
                                <br/>
                            </Col>
                        </Row>
                        <Row form className="mx-4">
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><DocumentImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("EVAL_IDEA_IMPACT")}:</p>
                                {hasImpacts === true ?
                                <FormRadio
                                    inline
                                    name="impact"
                                    checked={hasImpacts === true}
                                    onChange={() => {
                                    this.changeImpact(true);
                                    }}
                                >
                                    {t("EVAL_IDEA_YES")}
                                </FormRadio>
                                :
                                <FormRadio
                                    inline
                                    name="impact"
                                    checked={hasImpacts === false}
                                    onChange={() => {
                                    this.changeImpact(false);
                                    }}
                                >
                                    {t("EVAL_IDEA_NO")}
                                </FormRadio>
                                }
                                </Col>
                                </Row>
                            </Col>
                            {/* <Col md="4" className="form-group">
                                <Row form className="mx-4">
                                <Col md="3"><RibbonImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("EVAL_IDEA_RECOG")}:</p>
                               { hasRecognition === true ?<FormRadio
                                    inline
                                    name="isRecognized"
                                    checked={hasRecognition === true}
                                    onChange={() => {
                                    this.changeRecognition(true);
                                    }}
                                >
                                    {t("EVAL_IDEA_YES")}
                                </FormRadio>
                                :
                                <FormRadio
                                    inline
                                    name="isRecognized"
                                    checked={hasRecognition === false}
                                    onChange={() => {
                                    this.changeRecognition(false);
                                    }}
                                >
                                    {t("EVAL_IDEA_NO")}
                                </FormRadio>
                                }
                                </Col>
                                
                                </Row>
                            </Col> */}
                            <Col md="4" className="form-group">
                                <Row form className="mx-4">
                                <Col md="3"><RibbonImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">Additional Comments:</p>
                                  {comments ? (
                                    <FormTextarea 
                                      placeholder="N/A" 
                                      disabled 
                                      value={comments}
                                    />
                                  ) : (
                                    <FormInput
                                      placeholder="N/A"
                                      disabled 
                                    />
                                  )}
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        
                        {hasFile && <>
                          <Row form className="mt-4 mx-4">
                            <Col md="12" className="mx-4">
                              <label htmlFor="firstName" className="georgia">Verification Attachment</label>
                              <Button onClick={() => setModalVisible(true) } style={{display: 'flex'}} >View Attachment</Button>  
                            </Col>
                          </Row>
                          </>
                          }
                        </div>
                        </Form>
                    </CardBody>
                    
                  </Card>
                  </Col>
                  </Row>
                    </Col>
                  </Row>            
                
                  {hasFile && <IdeaAttachmentModal ideaItem={hasFile} onClick={setModalVisible} isVisible={modalVisible} type="verificationFile"/> }
            </>
          );
    }
}




export default withTranslation()(IdeaVerificationViewNew);

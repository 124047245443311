import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { Button, Card, CardBody, Col, Container, FormRadio, FormTextarea, Row } from "shards-react";
import OriginalIdeaInfoCompleted from '../components/administration/OriginalIdeaInfoCompleted';
import ActivityHistory from '../components/common/ActivityHistory';
import IdeaAttachmentModal from '../components/common/IdeaAttachmentModal';
import IdeaVerificationViewNew from '../components/common/IdeaVerificationViewNew';
import IdeaViewCardNew from '../components/common/IdeaViewCardNew';
import PageTitle from '../components/common/PageTitle';
import RecognitionView from '../components/common/RecognitionView';
import CustomFileWithProgressUpload from "../components/components-overview/CustomFileWithProgressUpload";
import ImplementedSuccess from '../components/ImplementedSucces';
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import { ReactComponent as SubmitIcon } from "../images/submit_new_new.svg";
import { ReactComponent as SubmitIconNosel } from "../images/submit_new_nosel.svg";
import { sendA3 } from '../services/implementService';
import opportunityService from '../services/opportunityService';
import { authUser } from '../services/userService';
import colors from "../utils/colors";


function CompletedIdeaFormNew({fromImplement = false, closeForms, closeModal, hiddenFormA3 = false, currIdea, stage, customTitle, closeA3, view}) {

  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(0)
  const [showIdeaInformation, setShowIdeaInformation] = useState(false)
  const [showIdeaA3, setShowIdeaA3] = useState(false)
  const [screen, setScreen] = useState("a3")
  const [isLoadingFetch, setIsLoadingFetch] = useState(false)
  const [openVerification, setOpenVerification] = useState(false)
  const [openRecognition, setOpenRecognition] = useState(false)
  const [canBeReplicated, setCanBeReplicated] = useState(false)
  const [goalAchieved, setGoalAchieved] = useState(false)
  const [goalTarget, setGoalTarget] = useState('')
  const [originalSituation, setOriginalSituation] = useState('')
  const [outcome, setOutcome] = useState('')
  const [additionalDetails, setAdditionalDetails] = useState('')
  const [hasResults, setHasResults] = useState(false)
  const [file, setFile] = useState(null)  
  const [isLoading, setIsLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const ref = useRef(null);
  const [ideaHasHistory, setIdeaHasHistory] = useState(false)
  const [completedHistory, setCompletedHistory] = useState(null); 
  const [isContentLoaded, setContentLoaded] = useState(false);
  const [progress, setProgress] = useState(100);
  const [opportunity, setOpportunity] = useState(currIdea); 
  const [completedTasks, setCompletedTasks] = useState(''); 
  const [ideaHasTasks, setIdeaHasTasks] = useState(false)

  const setScreenToShow = () => {
    console.log('called');
    
    if (opportunity.implement) {
      if (opportunity.implement.status === "Implemented") {
        setScreen("a3")
      } else {
        setScreen("success")
      }
    }
    setShowIdeaA3(true)
  }

  let currUser = authUser()
  const getUserName = async (user) => {
  
    setUsername(authUser.fullName)

    if (ideaStage === 0) {

      // We want to use t(VARIABLE) so that we can get the correct string
      // Replace 'VARIABLE' with t('VARIABLE_NAME')
      // VARIABLE_NAME being the corresponding variable in our new json file.

      // Uncomment the following line to create a message string
      // const welcomeMsg = 'VARIABLE' + username + '!'

      
      // Replace the contents of setTitle with our new welcomeMsg variable.
      if (customTitle) {
        setTitle(customTitle)
      } else {
        setTitle('Congratulations, you did it!')
      }
      // Here we are setting our title to what we want
    }
  }

  useEffect(() => {
    getUserName(currUser)
    hasHistory()
    fetchNextSteps()
    setContentLoaded(true);

    const fetchAndUpdateOpportunity = async (ideaId) => {
      if(isLoadingFetch) return;
      setIsLoadingFetch(true);
      try {
        const data = await opportunityService.fetchOpportunityIdeas(ideaId, 'verify');
        setOpportunity(data);
        getResultCard(data);
      } catch (error) {
        setIsLoadingFetch(false);
        console.error('Error fetching opportunity ideas:', error);
      } finally {
        setIsLoadingFetch(false);
      }
    };

  if (currIdea && currIdea.id && (stage==='verified' || stage==='completed' || stage==='recognized')) {
      fetchAndUpdateOpportunity(currIdea.id);
    }
  }, [currIdea]);

  const fetchNextSteps = async () => {
    try {
        const tasks = await opportunityService.getOpportunityTasks(currIdea.id);
        setCompletedTasks(tasks)
        setIdeaHasTasks(true)
    } catch (error) {
        console.error('Error fetching tasks:', error);
    }  
  }

  const hasHistory = async () => {
    try {
      const history = await opportunityService.fetchOpportunityHistory(currIdea.id);
      setCompletedHistory(history)
      setIdeaHasHistory(true)
    } catch (error) {
        console.error('Error fetching tasks:', error);
    }  
  }

  const getResultCard = async (data) => {

    if (data.implement) {
      setOriginalSituation(data.implement.originalSituation)
      setGoalTarget(data.implement.achievedResults)
      setGoalAchieved(data.implement.goalAchieved)
      setOutcome(data.implement.achievedResults)
      setAdditionalDetails(data.implement.additionalDetails)
      setCanBeReplicated(data.implement.canBeReplicated)
      setFile(data.implement.attachment)
      setHasResults(true)
    }else {
      setHasResults(false)
    }

  }

  const setFinished = () => {
    setIdeaStage(3)
  }

  const canSubmitForm = () => {
    if (originalSituation == '' || goalTarget == '') {
      return false
    } else {
      return true
    }
  }  

  const saveResults = async () => {
    if (progress !== 100) {
      alert('Please wait for the file to finish uploading.');
      return;
    }
    
    setIsLoading(true);
    const a3Data = {
      originalSituation: originalSituation,
      goal: goalTarget,
      goalAchieved: goalAchieved,
      achievedResults: outcome,
      additionalDetails: additionalDetails,
      canBeReplicated: canBeReplicated,
      fileId: file ? file.fileId : null,  
    };

    try {
     
      await sendA3(currIdea.id, a3Data);
      window.location.href = '/implement'
  
    } catch (error) {
      alert('Failed to save results: ' + error.message);
    } finally {
      setIsLoading(false)
    }
  };

  const convertBaseURL = (originalURL) => {
      const currentDomain = process.env.REACT_APP_PARSE_SERVER_URL ? process.env.REACT_APP_PARSE_SERVER_URL : 'https://demo-server.murmuratto.com/parse';
      const pathAfterFiles = originalURL.split('/files').pop();
      return `${currentDomain}/files${pathAfterFiles}`;
   };
  
   const handleGoBack = () => {    
    if (showIdeaInformation) {
      setShowIdeaInformation(false)
    } else if (showIdeaA3) {
      fromImplement && closeForms();
      setShowIdeaA3(false)
    } else if (openVerification) {
        setOpenVerification(false)
    } else if (openRecognition) {
      setOpenRecognition(false)
    } else if (!showIdeaInformation && !showIdeaA3 && !openVerification && !openRecognition) {
      fromImplement && closeForms()
      closeA3()
    } else {
      closeModal()
    }
   }  
   

   return isLoadingFetch ? <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30em'}}>
              <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80}/>
          </div> : (<>
  <Container fluid className="" style={{backgroundColor: 'white'}}>
  <Row noGutters className="page-header pt-4">
          <PageTitle
            title={'Overview'}
            subtitle={title}
            className="text-sm-left mb-3"
          />
        </Row>
    
    <div ref={ref} style={{flex: 1}}>
    {showIdeaInformation && <Row className="mt-4">
      <Col md="12" lg="12">
        <Row>
          <Col md="12" className="m-auto">
            <IdeaViewCardNew canSubmit={false} setFinishedSaving={() => setFinished()} ideaItem={opportunity} ideaStage={0}  />
          </Col>
        </Row>
      </Col>
    </Row>}

    {openVerification && <Row className="mt-4">
    <Col md="12" lg="12">
        <Row>
          <Col md="12" className="m-auto">
            <IdeaVerificationViewNew  dismissModal={() => console.log('Dismiss')} ideaItem={opportunity} onViewIdeaPress={() => console.log('View Idea')}/>
          </Col>
        </Row>
      </Col>
    </Row>}

    {openRecognition && <Row className="mt-4">
    <Col md="12" lg="12">
        <Row>
          <Col md="12" className="m-auto">
            <RecognitionView dismissModal={() => console.log('Dismiss')} ideaItem={opportunity} onViewIdeaPress={() => console.log('View Idea')}/>
          </Col>
        </Row>
      </Col>
    </Row>}

    {(screen === 'a3' && showIdeaA3) && <Row className="mt-4">
      <Col md="12" lg="12">
      <Row className="mt-4" >
      <Col md="12" className="m-auto">
        <Row>
      <Col md="6">
      <Card style={{ minHeight: "250px" }}>
                             <CardBody>
                             <label  htmlFor="question"><strong>{'Description'}: </strong></label>
                                  <FormTextarea
                               style={{ minHeight: "60px" }}
                               id="userBio"
                              //  placeholder={mejoras}
                               value={opportunity.submittal && opportunity.submittal.description}
                              //  onChange={this.setMejoras}
                               inactive
                               disabled
                             />
                              <br/>
                                                         
                                    <label  htmlFor="question"><strong>{'Describe the Original Situation/Condition'}: *</strong></label>
                                  <FormTextarea
                                    style={{ minHeight: "60px" }}
                                    id="userBio"
                                     placeholder={"Type Response Here."}
                                     value={originalSituation}
                                     onChange={(event) => setOriginalSituation(event.target.value)}
                                    inactive
                                    disabled={hasResults}
                                  />
                                  <br/>
                                  <label  htmlFor="question"><strong>{'Describe the Goal or Target?'}: *</strong></label>
                                  <FormTextarea
                                    style={{ minHeight: "60px" }}
                                    id="userBio"
                                    placeholder={"Type Response Here."}
                                     value={goalTarget}
                                     onChange={(event) => setGoalTarget(event.target.value)}
                                    inactive
                                    disabled={hasResults}
                                  />
                                  <br/>
                                  <label  htmlFor="question"><strong>{t('Was the Goal/Target achieved?')} *</strong></label>
                                  <br/>
                                  <FormRadio
                                    inline
                                    name="goalAchieved"
                                    checked={goalAchieved == true}
                                    onChange={() => {
                                      setGoalAchieved(true)
                                    }}
                                    disabled={hasResults}
                                  >
                                    Yes
                                  </FormRadio>
                                  <FormRadio
                                    inline
                                    name="goalAchieved"
                                    checked={goalAchieved == false}
                                    onChange={() => {
                                      setGoalAchieved(false)
                                    }}
                                    disabled={hasResults}
                                  >
                                    No
                                  </FormRadio>
                                  <br/>
                                  <br/>
                                  <label  htmlFor="question"><strong>{t("COULD_BE_REPLICATED")} *</strong></label>
                                  <br/>
                                  <FormRadio
                                    inline
                                    name="canBeReplicated"
                                    checked={canBeReplicated == true}
                                    onChange={() => {
                                      setCanBeReplicated(true)
                                    }}
                                    disabled={hasResults}
                                  >
                                    Yes
                                  </FormRadio>
                                  <FormRadio
                                    inline
                                    name="canBeReplicated"
                                    checked={canBeReplicated == false}
                                    onChange={() => {
                                      setCanBeReplicated(false)
                                    }}
                                    disabled={hasResults}
                                  >
                                    No
                                  </FormRadio>
                                  <br/>
                                  
                             </CardBody>
                            </Card>
      </Col>
      <Col md="6">
      <Card className="h-100" style={{ minHeight: "250px" }}>
                             <CardBody>
                             <label  htmlFor="question"><strong>{'Describe the achieved results/outcome:'} </strong></label>
                                  <FormTextarea
                               style={{ minHeight: "60px" }}
                               id="userBio"
                               placeholder={"Type Response Here."}
                               value={outcome}
                               onChange={(event) => setOutcome(event.target.value)}
                               inactive
                               disabled={hasResults}
                             />
                              <br/>
                              <label  htmlFor="question"><strong>{'Add any additional details:'} </strong></label>
                                  <FormTextarea
                               style={{ minHeight: "60px" }}
                               id="userBio"
                               placeholder={"Type Response Here."}
                               value={additionalDetails}
                               onChange={(event) => setAdditionalDetails(event.target.value)}
                               inactive
                               disabled={hasResults}
                             />
                              <br/>

                              <div>
                                
                              </div>             
                              {/* Only Show if There are Tasks  */}
                             
                              <div>
                              {!hasResults &&<label  htmlFor="question"><strong>{'Add an attachment'}: </strong></label>}
                              {!hasResults &&
                                <CustomFileWithProgressUpload maximumSizeFile={10}
                                onProgressUpdate={(currentProgress) => {
                                  setProgress(currentProgress)
                                }}
                                onFileUpload={(file) => {
                                  this.setState({ file })
                                }}
                                />
                              }
                              {hasResults && file && <Button onClick={() =>setModalVisible(true)} style={{display: 'flex'}} >{'View Attachment'}</Button>  }
                              </div>
                             </CardBody>
                            </Card>
      </Col>
      

      </Row>
     { ideaHasHistory && 
          <Row className="mt-4">
            <Col md="12" lg="12" className="mt-4">
                <ActivityHistory currIdea={completedHistory} history={completedHistory} />
              </Col>
          </Row> }
      </Col>
    </Row>
      </Col>
    </Row>}

    {
          (showIdeaA3 && screen === "success") && <ImplementedSuccess hideArrow={true} form={opportunity.implement.status} comments={opportunity.implement.comments} file={opportunity.implement.attachment} currIdea={opportunity} />
        }

    <Row className="mt-4">
      <Col md="12" lg="12">

      </Col>
      </Row>
    {!showIdeaInformation && !showIdeaA3 && !openVerification && !openRecognition &&
    <div>

    <div style={{flex: 1}}>
      <Row className={view === 'verify-history' ? "mt-0" : 'mt-4'}>
        <Col md="12" lg="12">
          <OriginalIdeaInfoCompleted view={view} screen={screen} currIdea={opportunity} openIdea={() => setShowIdeaInformation(true)} key={Math.random()} openIdeaA3={() => setScreenToShow()} openVerification={() => setOpenVerification(true)}  openRecognition={() => setOpenRecognition(true)} id={Math.random()}  open={true} shouldReset={false} updateUsers={() => {console.log('')}} user={{}} stage={stage}></OriginalIdeaInfoCompleted>
        </Col>
      </Row>


      {currIdea.status === 'Duplicate' && view === 'recognition' && 
      <h6 style={{ fontWeight: 500, color: '#303030',  width: "100%", marginTop: '40px', textAlign: 'center'}}>
        {t('DuplicateDetails')}
      </h6>}


      {!opportunity.implement && view !== 'recognition' && !hiddenFormA3  && 
      <Row className="mt-4">
        <Col md="12" lg="12">
        <Row className="mt-4" >
        <Col md="12" className="m-auto">
          <Row>
          <h3 style={{margin: "20px 0 40px 0", textAlign: "center", width: "100%", color: "#000"}}>Implementation Analysis</h3>

        <Col md="6">
        <Card style={{ minHeight: "250px" }}>
                              <CardBody>
                              <label  htmlFor="question"><strong>{'Describe the Opportunity'}: </strong></label>
                                    <FormTextarea
                                style={{ minHeight: "60px" }}
                                id="userBio"
                                value={opportunity.submittal && opportunity.submittal.description}
                                inactive
                                disabled
                              />
                                <br/>
                                                          
                                      <label  htmlFor="question"><strong>{'Describe the Original Situation/Condition'}: *</strong></label>
                                    <FormTextarea
                                      style={{ minHeight: "60px" }}
                                      id="userBio"
                                      placeholder={"Type Response Here."}
                                      value={originalSituation}
                                      onChange={(event) => setOriginalSituation(event.target.value)}
                                      inactive
                                      disabled={hasResults}
                                    />
                                    <br/>
                                    <label  htmlFor="question"><strong>{'Describe the Goal or Target?'}: *</strong></label>
                                    <FormTextarea
                                      style={{ minHeight: "60px" }}
                                      id="userBio"
                                      placeholder={"Type Response Here."}
                                      value={goalTarget}
                                      onChange={(event) => setGoalTarget(event.target.value)}
                                      inactive
                                      disabled={hasResults}
                                    />
                                    <br/>
                                    <label  htmlFor="question"><strong>{t('Was the Goal/Target achieved?')} *</strong></label>
                                    <br/>
                                    <FormRadio
                                      inline
                                      name="goalAchieved"
                                      checked={goalAchieved == true}
                                      onChange={() => {
                                        setGoalAchieved(true)
                                      }}
                                      disabled={hasResults}
                                    >
                                      Yes
                                    </FormRadio>
                                    <FormRadio
                                      inline
                                      name="goalAchieved"
                                      checked={goalAchieved == false}
                                      onChange={() => {
                                        setGoalAchieved(false)
                                      }}
                                      disabled={hasResults}
                                    >
                                      No
                                    </FormRadio>
                                    <br/>
                                    <br/>
                                    <label  htmlFor="question"><strong>{t("COULD_BE_REPLICATED")} *</strong></label>
                                    <br/>
                                    <FormRadio
                                      inline
                                      name="canBeReplicated"
                                      checked={canBeReplicated == true}
                                      onChange={() => {
                                        
                                        setCanBeReplicated(true)
                                      }}
                                      disabled={hasResults}
                                    >
                                      Yes
                                    </FormRadio>
                                    <FormRadio
                                      inline
                                      name="canBeReplicated"
                                      checked={canBeReplicated == false}
                                      onChange={() => {
                                        setCanBeReplicated(false)
                                      }}
                                      disabled={hasResults}
                                    >
                                      No
                                    </FormRadio>
                                    <br/>
                                    
                              </CardBody>
                              </Card>
        </Col>

        <Col md="6">
        <Card className="h-100" style={{ minHeight: "250px" }}>
                              <CardBody>
                              <label  htmlFor="question"><strong>{'Describe the achieved results/outcome:'} </strong></label>
                                    <FormTextarea
                                style={{ minHeight: "60px" }}
                                id="userBio"
                                placeholder={"Type Response Here."}
                                value={outcome}
                                onChange={(event) => setOutcome(event.target.value)}
                                inactive
                                disabled={hasResults}
                              />
                                <br/>
                                <label  htmlFor="question"><strong>{'Add any additional details:'} </strong></label>
                                    <FormTextarea
                                style={{ minHeight: "60px" }}
                                id="userBio"
                                placeholder={"Type Response Here."}
                                value={additionalDetails}
                                onChange={(event) => setAdditionalDetails(event.target.value)}
                                inactive
                                disabled={hasResults}
                              />
                                <br/>
                                                        
                              
                                <div>
                                {!hasResults &&<label  htmlFor="question"><strong>{'Add an attachment'}: </strong></label>}
                                {!hasResults &&  
                                <span> 
                                  <CustomFileWithProgressUpload maximumSizeFile={10}
                                    onProgressUpdate={(currentProgress) => {
                                      setProgress(currentProgress)
                                    }}w
                                    onFileUpload={(file) => {
                                      setFile(file)
                                    }}
                                    />
                                  </span> }
                                {hasResults && file && <Button onClick={() => window.open(convertBaseURL(file._url))} style={{display: 'flex'}} >{'View Attachment'}</Button>  }
                                </div>
                              </CardBody>
                              </Card>
        </Col>
        

       </Row>
       </Col>
       </Row>
       </Col>
      </Row>}


    
    </div>
    
    </div>
    }
    </div>
   
    <Row className="mt-4">
      <Col lg="2" className="mb-4 mr-auto">
        <PreviousIcon className="functionalButton mr-auto d-block" style={{minWidth: 140, maxWidth:140}} onClick={() => handleGoBack()}></PreviousIcon>
      
        </Col>
          <Col lg="8" className="mb-4 mx-auto my-auto">
            
          </Col>
          <Col lg={2} className="mb-4 ml-auto">
        {canSubmitForm() && hasResults === false && !isLoading &&(
          <SubmitIcon
            className="functionalButton ml-auto d-block"
            style={{ minWidth: 140, maxWidth: 140 }}
            onClick={() => saveResults()}
          />
        )}
      {isLoading && (
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '10em'
          }}
        >
          <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
        </div>
      )}
      {!stage && 
        (!canSubmitForm() && hasResults === false && !isLoading) && (
        <SubmitIconNosel
          className="functionalButton ml-auto d-block"
          style={{ minWidth: 140, maxWidth: 140 }}
        />
      )}
    </Col>

    </Row>
    {file && <IdeaAttachmentModal ideaItem={file} onClick={setModalVisible} isVisible={modalVisible} file={file} type="file"/>}
  </Container></>)}


CompletedIdeaFormNew.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(CompletedIdeaFormNew);
